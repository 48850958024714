import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import PageWrapper from "Commons/PageWrapper";
import { Container, Col, Row } from 'react-grid-system';
import { StaticImage } from "gatsby-plugin-image";
import "./styles.scss";

const Service = ({ children, title, text }) => (
  <div className="service">
    <h2 className="service--title">{title}</h2>
    {children}
    {text}
  </div>
);

const ImageWithGrowth = ({ image }) => {
  return (
    <div className="image-growth">{image}</div>
  )
};

const imageToComponent = image => <ImageWithGrowth image={image} />;

const firstCarouselImages = [
  <StaticImage src="../../images/entidades/01-transporte-de-caudales/c1.png" />,
  <StaticImage src="../../images/entidades/01-transporte-de-caudales/c2.png" />,
  <StaticImage src="../../images/entidades/01-transporte-de-caudales/c3.png" />,
  <StaticImage src="../../images/entidades/01-transporte-de-caudales/c4.png" />,
  <StaticImage src="../../images/entidades/01-transporte-de-caudales/c5.png" />,
  <StaticImage src="../../images/entidades/01-transporte-de-caudales/c6.png" />,
]

const secondCarouselImages = [
  <StaticImage src="../../images/entidades/02-seguridad-bancaria/seg1.png" />,
  <StaticImage src="../../images/entidades/02-seguridad-bancaria/seg2.png" />,
  <StaticImage src="../../images/entidades/02-seguridad-bancaria/seg3.png" />,
  <StaticImage src="../../images/entidades/02-seguridad-bancaria/seg4.png" />,
  <StaticImage src="../../images/entidades/02-seguridad-bancaria/seg5.png" />,
  <StaticImage src="../../images/entidades/02-seguridad-bancaria/seg6.png" />,
]

const thirdCarouselImages = [
  <StaticImage src="../../images/entidades/03-bancos-moviles/mov1.png" />,
  <StaticImage src="../../images/entidades/03-bancos-moviles/mov2.png" />,
  <StaticImage src="../../images/entidades/03-bancos-moviles/mov3.png" />,
]

const EntidadesBancarias = () => {
  return (
    <PageWrapper dark>
      <Helmet>
        <title>
          Entidades | Interservit
        </title>
      </Helmet>
      <section className="entidades content _center">
        <div className="entidades-header">
          <Row>
            <Col xs={12} sm={8}>
              <h2 className="entidades--title _sub-heading">
                ENTIDADES BANCARIAS Y FINANCIERAS
              </h2>
              <p className="entidades--paragraph">
                Desde sus principios hasta el día de hoy, Interservit brindó servicio a las principales entidades
                financieras y bancarias. Algunos de nuestros principales clientes como Prosegur, Banco Central de
                la República Argentina, Banco Provincia de Buenos Aires, BBVA, Banco Galicia de Buenos Aires,
                Banco Macro, Banco Itau Argentina confían en nosotros.
              </p>
            </Col>
            <Col xs={12} sm={4} className="entidades--stamp">
              <StaticImage
                className="sello-certificado"
                src="../../images/tuv_certified.png"
              />
            </Col>
          </Row>
        </div>
        <Service
          title="SERVICIO DE TRANSPORTE DE CAUDALES"
          text={
            <p className="service--text">
              Llevamos más de 1800 camiones blindados construidos en todo el mercado de Argentina. <br />
              Somos proveedores estratégicos de Prosegur y Brinks: desarrollamos unidades especiales para ellos y exportamos camiones a Uruguay y Paraguay.
            </p>
          }
        >
          <div className="service--photos">
            {firstCarouselImages.map(imageToComponent)}
          </div>
        </Service>
        <Service
          title="SERVICIO PARA SEGURIDAD BANCARIA"
          text={
            <p className="service--text">
              Bajo el acuerdo de Ley 19.130 de Seguridad Bancaria (normativa del B.C.R.A.) y sus decretos reglamentarios, construimos cajas, tesoros, puertas, etc., resistentes a incendios, perforaciones  mecánicas, soplete oxhídrico y oxiarco.
            </p>
          }
        >
          <div className="service--photos">
            {secondCarouselImages.map(imageToComponent)}
          </div>
        </Service>
        <Service
          title="BANCOS MÓVILES"
          text={
            <p className="service--text">
              Para las distintas entidades bancarias, desarrollamos bancos móviles blindados que brindan servicio como sucursales y cajeros en distintos puntos del país.
            </p>
          }
        >
          <div className="service--photos">
            {thirdCarouselImages.map(imageToComponent)}
          </div>
        </Service>
        <Container className="entidades--clients">
          <Row>
            <Col xs={10} offset={{ xs: 1, md: 0 }} md={4}>
              <ul>
                <li>Prosegur Argentina</li>
                <li>Prosegur Uruguay</li>
                <li>Prosegur Paraguay</li>
                <li>Brinks Argentina</li>
                <li>Banco Central de la República Argentina</li>
                <li>Banco Provincia de Buenos Aires</li>
              </ul>
            </Col>
            <Col xs={10} offset={{ xs: 1, md: 0 }} md={4}>
              <ul>
                <li>Banco de Santa Fe</li>
                <li>Banco de la Provincia de Corrientes</li>
                <li>Banco de la Provincia de Neuquén</li>
                <li>BBVA</li>
                <li>Banco Galicia de Buenos Aires</li>
                <li>Banco Macro</li>
              </ul>
            </Col>
            <Col xs={10} offset={{ xs: 1, md: 0 }} md={4}>
              <ul>
                <li>Loomis</li>
                <li>Bacar</li>
                <li>Finor</li>
                <li>Caja Popular de Ahorro de la Provincia de Tucumán</li>
                <li>Nuevo Banco de Chaco</li>
                <li>Banco de la Nación Argentina</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </PageWrapper>
  );
};

export default EntidadesBancarias;
